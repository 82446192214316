import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { SecondarySquareButton, SuccessSquareButton, TertiarySquareButton } from '@entur/button';
import { AddIcon, CheckIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<div\n  style={{ display: \'flex\', justifyContent: \'center\', marginBottom: \'1em\' }}\n>\n  <SecondarySquareButton aria-label=\"Legg til\">\n    <AddIcon />\n  </SecondarySquareButton>\n  <SecondarySquareButton style={{ marginLeft: \'1em\' }} aria-label=\"Legg til\">\n    <AddIcon />\n  </SecondarySquareButton>\n</div>\n<div\n  style={{ display: \'flex\', justifyContent: \'center\', marginBottom: \'1em\' }}\n>\n  <SecondarySquareButton>\n    Legg til <AddIcon />\n  </SecondarySquareButton>\n  <SecondarySquareButton style={{ marginLeft: \'1em\' }}>\n    <AddIcon /> Legg til\n  </SecondarySquareButton>\n</div>\n<div\n  style={{ display: \'flex\', justifyContent: \'center\', marginBottom: \'1em\' }}\n>\n  <SuccessSquareButton>\n    Lagre <CheckIcon />\n  </SuccessSquareButton>\n  <SuccessSquareButton style={{ marginLeft: \'1em\' }}>\n    <CheckIcon /> Lagre\n  </SuccessSquareButton>\n</div>\n<div style={{ display: \'flex\', justifyContent: \'center\' }}>\n  <TertiarySquareButton>\n    Lagre <CheckIcon />\n  </TertiarySquareButton>\n  <TertiarySquareButton style={{ marginLeft: \'1em\' }}>\n    <CheckIcon /> Lagre\n  </TertiarySquareButton>\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      SecondarySquareButton,
      SuccessSquareButton,
      TertiarySquareButton,
      AddIcon,
      CheckIcon,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1em'
      }}>
    <SecondarySquareButton aria-label="Legg til" mdxType="SecondarySquareButton">
      <AddIcon mdxType="AddIcon" />
    </SecondarySquareButton>
    <SecondarySquareButton style={{
          marginLeft: '1em'
        }} aria-label="Legg til" mdxType="SecondarySquareButton">
      <AddIcon mdxType="AddIcon" />
    </SecondarySquareButton>
  </div>
  <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1em'
      }}>
    <SecondarySquareButton mdxType="SecondarySquareButton">
      Legg til <AddIcon mdxType="AddIcon" />
    </SecondarySquareButton>
    <SecondarySquareButton style={{
          marginLeft: '1em'
        }} mdxType="SecondarySquareButton">
      <AddIcon mdxType="AddIcon" /> Legg til
    </SecondarySquareButton>
  </div>
  <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1em'
      }}>
    <SuccessSquareButton mdxType="SuccessSquareButton">
      Lagre <CheckIcon mdxType="CheckIcon" />
    </SuccessSquareButton>
    <SuccessSquareButton style={{
          marginLeft: '1em'
        }} mdxType="SuccessSquareButton">
      <CheckIcon mdxType="CheckIcon" /> Lagre
    </SuccessSquareButton>
  </div>
  <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
    <TertiarySquareButton mdxType="TertiarySquareButton">
      Lagre <CheckIcon mdxType="CheckIcon" />
    </TertiarySquareButton>
    <TertiarySquareButton style={{
          marginLeft: '1em'
        }} mdxType="TertiarySquareButton">
      <CheckIcon mdxType="CheckIcon" /> Lagre
    </TertiarySquareButton>
  </div>
    </Playground>
    <p>{`Kvadratiske knapper skal alltid ha ett ikon, og `}<em parentName="p">{`kan`}</em>{` ha en label på enten venstre eller høyre side. Du kan også bruke dem uten label, men bør da sende inn en alternativ beskrivelse med `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "successsquarebutton"
    }}>{`SuccessSquareButton`}</h3>
    <ImportStatement imports="SuccessSquareButton" mdxType="ImportStatement" />
    <h3 {...{
      "id": "secondarysquarebutton"
    }}>{`SecondarySquareButton`}</h3>
    <ImportStatement imports="SecondarySquareButton" mdxType="ImportStatement" />
    <h3 {...{
      "id": "tertiarysquarebutton"
    }}>{`TertiarySquareButton`}</h3>
    <ImportStatement imports="TertiarySquareButton" mdxType="ImportStatement" />
    <Props of={SuccessSquareButton} mdxType="Props" />
    <p>{`Alle tre komponentene har samme API, og brukes på samme måte. Se på "standardknapper"-siden for retningslinjer om når du burde bruke Success, Secondary eller Tertiary.`}</p>
    <p>{`Knappen regner med at du sender inn label-teksten som en ren string, og ikonet som en komponent. Med andre ord på denne måten:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<SecondarySquareButton>
  <RandomIkon /> En label-tekst
</SecondarySquareButton>
`}</code></pre>
    <p>{`Du kan ikke plassere teksten inni en `}<inlineCode parentName="p">{`<span />`}</inlineCode>{` eller lignende, da komponenten da vil tolke den biten som et ikon. Dette vil med andre ord `}<em parentName="p">{`ikke`}</em>{` fungere:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<SecondarySquareButton>
  <RandomIkon /> <span>En label-tekst</span>
</SecondarySquareButton>
`}</code></pre>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Fungerer som handlingsknapper som består av av et ikon.
Ikonet skal være selvforklarende for handlingen samt ha en Tooltip med en beskrivende tekst ved hover.
Denne knappen skal benyttes i f.eks disse tilfellene; Legge til, laste ned, laste opp osv.`}</p>
    <p>{`Kvadratiske knapper skal alltid ha ett ikon, og kan ha en label på enten venstre eller høyre side.
Du kan også bruke dem uten label, men bør da sende inn en alternativ beskrivelse med aria-label.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      